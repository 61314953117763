import React, { useState } from "react";
import { Link } from "react-router-dom";

export default function CodeInput() {
  const [verified, setVerified] = useState(false);
  const [codeInput, setcodeInput] = useState("");
  const [prompt, setPrompt] = useState("Enter Code");

  const renderCodeCheck = () => {
    return verified ? (
      <span>pass</span>
    ) : (
      <>
        <br />
        <Link to="/">
          <span className="white center ">Back</span>
        </Link>
        <h2 className=""> {prompt} </h2>
        <input
          className="mv3"
          type="password"
          maxlength="6"
          value={codeInput}
          onChange={e => setcodeInput(e.target.value)}
        />
        <br />
        <button onClick={() => checkCode()}>submit</button>
      </>
    );
  };

  const checkCode = () => {
    codeInput === "030918" ? setVerified(true) : setPrompt("Invalid");
  };

  return (
    <article className="vh-100 dt w-100 bg-black">
      <div className="dtc v-mid tc white ">{renderCodeCheck()}</div>
    </article>
  );
}
