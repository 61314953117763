import React, { useState } from "react";
import "react-phone-number-input/style.css";
import Input from "react-phone-number-input/input";

const URL = process.env.REACT_APP_SERVER_URL;

export default function HomePage({ history }) {
  const [numberInput, setNumberInput] = useState("");
  const [prompt, setPrompt] = useState("");
  const [status, setStatus] = useState(false);
  const [code, setCode] = useState("");

  const redirectToStore = () => {
    return setTimeout(
      () => window.open("https://store.deathonwax.com", "_self"),
      3000
    );
  };

  const checkPhone = async (e) => {
    e.preventDefault();
    const number = numberInput;
    if (number.length < 12) return alert("Enter cell # ");
    try {
      const serverRes = await fetch(`${URL}/api/numberverify`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ number }),
      });
      switch (serverRes.status) {
        case 201:
          setStatus(true);
          setPrompt("Check your Phone");
          break;
        case 403:
        case 406:
          setStatus(false);
          setPrompt(
            "You're already in the books...redirecting you to the store"
          );
          setNumberInput("");
          redirectToStore();
          break;
        case 400:
          setPrompt(
            "Client/Server fuzzy, TRY AGAIN...redirecting you to the store"
          );
          redirectToStore();
          break;
        case 503:
          setPrompt("Are you sure thats a (c)# ?---TRY AGAIN");
          break;
        case 500:
        default:
          setStatus(false);
          setPrompt(
            `${serverRes.status} Error:::Email Engineering@DeathonWax.com...redirecting you to the store`
          );
          setNumberInput("");
          redirectToStore();
          break;
      }
    } catch (err) {
      console.error(err);
      setPrompt(`Error:::Email Engineering@DeathonWax.com`);
      setNumberInput("");
    }
  };

  const checkCode = async (e) => {
    e.preventDefault();

    const event = "death on wax";
    const number = numberInput;
    if (!code || code.length !== 6) {
      return alert("Code must be 6 digits long.");
    }
    try {
      const serverRes = await fetch(`${URL}/api/codeverify`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ code, number, event }),
      });

      switch (serverRes.status) {
        case 404:
          setStatus(true);
          setPrompt(
            `${serverRes.status} Error:::Email Engineering@DeathWax.com`
          );
          break;
        case 200:
        case 204:
          setStatus(false);
          setPrompt(
            "Logged, stand by for rollcall...redirecting you to the store"
          );
          setCode("");
          redirectToStore();
          break;
        case 400:
          setPrompt("Client/Server fuzzy, TRY AGAIN");
          redirectToStore();
          break;
        case 500:
        case 406:
          setPrompt(
            "Invalid Code try again or Email Engineering@DeathonWax.com"
          );
          redirectToStore();
          break;
        case 504:
        default:
          setStatus(false);
          setPrompt(
            `${serverRes.status} Error:::Email Engineering@DeathWax.com...redirecting you to the store`
          );
      }
    } catch (err) {
      console.error(err);
      setPrompt(
        `Error:::Email Engineering@DeathonWax.com...redirecting you to the store`
      );
      setNumberInput("");
      redirectToStore();
    }
  };

  const renderForm = () => {
    if (prompt.length && !status) {
      return (
        <div>
          <span>{prompt}</span>;
        </div>
      );
    } else if (status) {
      return (
        <>
          <span className="message dark-red">{prompt}</span>
          <br />
          <span>ENTER THE CODE</span>
          <br />
          <input
            type="number"
            onChange={(e) => setCode(e.target.value)}
            placeholder="2FA"
            value={code}
            max={9999}
            className="mv3"
          />
          <br />
          <button className="text-focus-in " onClick={(e) => checkCode(e)}>
            SUBMIT
          </button>
        </>
      );
    } else {
      return (
        <>
          <form>
            <Input
              limitMaxLength={true}
              placeholder="cell"
              defaultCountry="US"
              value={numberInput}
              style={{ textAlign: "center" }}
              onChange={(value) => setNumberInput(value)}
              className="center w5 tracking-in-expand-fwd mb3"
            />
            <br />
            <button className="text-focus-in " onClick={(e) => checkPhone(e)}>
              {" "}
              submit{" "}
            </button>
          </form>
        </>
      );
    }
  };

  return (
    <article className="vh-100 dt w-100 bg-black">
      <div className="dtc pt4 tc moon-gray ph3 ph4-l">
        <a href="https://store.deathonwax.com">
       <div className="white pb5 f3">INVENTORY</div>
         {/* <div className="dark-red pb5 f3 code">RSVP BELOW</div>*/}
          <img
            className="w-25-ns w-75 text-focus-in w-50"
            src="/img/logo.gif"
            alt="DEATHWAX-1000"
          />
        </a>
        <h1 className=" f2-m f2-l  fw6 tc purple focus-in-contract ">
          DEATH on WAX
        </h1>
        {renderForm()}
      </div>
    </article>
  );
}
