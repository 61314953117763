import React from "react";
import "./App.css";
import "tachyons";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import HomePage from "./components/HomePage.js";
import CodeInput from "./components/CodeInput.js";

export default function App() {
  return (
    <div className="bg-black">
      <Router>
        <Switch>
          <Route exact={true} path="/" component={HomePage} />
          <Route exact={true} path="/portal" component={CodeInput} />
        </Switch>
      </Router>
    </div>
  );
}
